import React from "react"
import { BrowserRouter as Router, useRoutes } from "react-router-dom"
import ActivityIndicatorComponent from "../../components/utils/ActivityIndicatorComponent"
import AccessAuthenticator from "../../components/auth/AccessAuthenticator"
import { FadeInDiv } from "../../components/styled"

// import AuthProvider from "../../components/providers/AuthProvider"
import AppLayoutComponent from "../layout"
const { REACT_APP_BASENAME } = process.env

const dynamicImport = (page: string): React.FC => {
  return React.lazy(() =>
    import(`../../../pages/${page}`)
      .then(screen => {
        return screen
      })
      .catch(err => console.log(err))
  )
}
const AuthPage = dynamicImport("auth")
const HomePage = dynamicImport("map")

const NotFoundPage = dynamicImport("notfound")

const AppRoutesConfiguration = () => {
  const routes = useRoutes([
    {
      path: "/",
      element: (
        <React.Suspense fallback={<ActivityIndicatorComponent />}>
          <AccessAuthenticator admin_access={false}>
            <AppLayoutComponent>
              <FadeInDiv>
                <HomePage />
              </FadeInDiv>
            </AppLayoutComponent>
          </AccessAuthenticator>
        </React.Suspense>
      ),
    },
    {
      path: "/auth",
      children: [
        {
          index: true,
          element: (
            <React.Suspense fallback={<ActivityIndicatorComponent />}>
              <FadeInDiv>
                <AuthPage />
              </FadeInDiv>
            </React.Suspense>
          ),
        },
      ],
    },

    {
      path: "*",
      element: (
        <React.Suspense fallback={<ActivityIndicatorComponent />}>
          <NotFoundPage />
        </React.Suspense>
      ),
    },
  ])

  return routes
}

const RouteWrapper = () => {
  return (
    <Router basename={REACT_APP_BASENAME || "/"}>
      <AppRoutesConfiguration />
    </Router>
  )
}

export default RouteWrapper
