export const dark = {
  brand: {
    primary: "#f38e27",
    secondary: "#ffb74d",
    muted: "#C6DAF7",
  },
  ui: {
    primary: "#f5f5f5",
    secondary: "#757575",
    tertiary: "#F1F1F1",
    quaternary: "#FFFFFF",
    disabled: "#DEDEDE",
    error: "#D0421B",
    success: "#138000",
    shadow: "rgba(254, 254, 254, 0.8)",
  },
  bg: { primary: "#000000", secondary: "#323232", card: "#323232" },
  text: {
    primary: "#e0e0e0",
    secondary: "#757575",
    disabled: "#9C9C9C",
    inverse: "#FFFFFF",
    error: "#D0421B",
    success: "#138000",
  },
}

export const light = {
  brand: {
    primary: "#ff9100",
    secondary: "#ffe0b2",
    muted: "#C6DAF7",
  },
  ui: {
    primary: "#f5f5f5",
    secondary: "#757575",
    tertiary: "#F1F1F1",
    quaternary: "#FFFFFF",
    disabled: "#DEDEDE",
    error: "#D0421B",
    success: "#138000",
    shadow: "rgba(0, 0, 0, 0.8)",
  },
  bg: { primary: "#fafafa", secondary: "#fafafa", card: "" },
  text: {
    primary: "#272727",
    secondary: "#757575",
    disabled: "#9C9C9C",
    inverse: "#FFFFFF",
    error: "#D0421B",
    success: "#138000",
  },
}
