const size = {
  mobile_small: "320px",
  mobile_medium: "375px",
  mobile_large: "425px",
  tablet: "768px",
  laptop: "1024px",
  laptop_large: "1440px",
  desktop: "2560px",
}

const device = {
  mobile_small: `(max-width: ${size.mobile_small})`,
  mobile_medium: `(max-width: ${size.mobile_medium})`,
  mobile_large: `(max-width: ${size.mobile_large})`,
  tablet: `(max-width: ${size.tablet})`,
  laptop: `(max-width: ${size.laptop})`,
  laptop_large: `(max-width: ${size.laptop_large})`,
  desktop: `(max-width: ${size.desktop})`,
  desktop_large: `(max-width: ${size.desktop})`,
}

export default device
