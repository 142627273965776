import React from "react"

type Props = {
  children: JSX.Element
}

const index: React.FC<Props> = ({ children }) => {
  return <section>{children}</section>
}

export default index
