import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"
import type { IUser } from "./authAPI"

type AuthState = {
  user: IUser
  token: string
  is_logged_in: boolean
}

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: {
      id: "",
      email: "",
      is_admin: false,
      is_billing: false,
      fullname: "",
    },
    token: "",
    is_logged_in: false,
  } as AuthState,
  reducers: {
    setLogout: state => {
      state.is_logged_in = false
      state.user = { id: "", is_admin: false, is_billing: false, fullname: "", email: "" }
      state.token = ""
    },
    setCredentials: (
      state,
      { payload: { user, token, is_logged_in } }: PayloadAction<{ user: IUser; token: string; is_logged_in: boolean }>
    ) => {
      state.user = user
      state.token = token
      state.is_logged_in = is_logged_in
    },
  },
})

export const { setCredentials, setLogout } = authSlice.actions

export default authSlice.reducer

// export const selectCurrentUser = (state: RootState) => state.auth.user
