import styled, { keyframes } from "styled-components"
import { fadeIn, slideInRight, bounce } from "react-animations"

const slideInAnimation = keyframes`${slideInRight}`

const fadeInAnimation = keyframes`${fadeIn}`

const bounceAnimation = keyframes`${bounce}`

interface center_container {
  position: string | undefined
  height: string | undefined
  width: string | undefined
}
export const CenterContainer = styled.div<center_container>`
  border-style: none;
  height: ${props => (props.height ? props.height : "fit-content")};
  margin: auto;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: ${props => (props.position ? props.position : "relative")};
  width: ${props => (props.width ? props.width : "fit-content")};
`
export const Avatar = styled.div`
  border-radius: 50%;
  width: 120px;
  height: 100%;
  position: relative;
  margin: 0 auto;
`

export const ResponsiveImage = styled.img`
  width: 100%;
  display: block;
  border-radius: 50%;
  position: relative;
  bottom: 0;
  border: 4px solid ${props => props.theme.colors.brand.primary};
  box-shadow: 4px 0px 24px 8px ${props => props.theme.colors.brand.secondary};
`
export const ResponsiveImageContainer = styled.img`
  width: 100%;
  display: block;
  position: relative;
  bottom: 0;
`

export const FileImageContainer = styled.img`
  width: 100%;
  display: block;
  position: relative;
`

export const FileContainer = styled.div`
  width: fit-content;
  height: fit-content;
  display: block;
  position: relative;
  padding: 0.3rem;
  bottom: 0;
  /* border: 4px solid ${props => props.theme.colors.brand.primary}; */
  box-shadow: 4px 0px 24px 8px ${props => props.theme.colors.brand.secondary};
`

export const ShodowContainer = styled.div`
  box-shadow: "rgb(220, 220, 220, 0.4) 0px 4px 55px";
`

interface ICounter {
  bg: string | undefined
}

export const CardContainer = styled.div<ICounter>`
  /* color: ${props => (props.color ? props.color : "#000")}; */
  background-color: ${props => (props.bg ? props.bg : "#f3e5f5")};
  border: 1px solid ${props => (props.bg ? props.bg : "none")};
  border-radius: 10px;
  box-shadow: 4px 0px 24px 8px rgb(220, 220, 220, 0.5);
  margin: 1rem;
  display: inline-flex;
  width: fit-content;
  min-width: 120px;
  height: fit-content;
  min-height: 100px;
  padding: 1rem;
`

export const FadeInDiv = styled.div`
  animation: 2s ${fadeInAnimation};
`

export const SlideInRightDiv = styled.div`
  animation: 3s ${slideInAnimation};
`

export const BounceDiv = styled.div`
  animation: 3s ${bounceAnimation};
`

interface ICard {
  height: string
  width: string
}
export const Card = styled("div")<ICard>`
  background: ${props => props.theme.colors.bg.card};
  border-radius: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  color: ${props => props.theme.colors.text.primary};
  cursor: pointer;
  height: ${props => (props.height ? props.height : "fit-content")};
  min-height: "fit-cotent";
  overflow: hidden;
  padding: 1rem;
  transition: 0.2s;
  width: ${props => (props.width ? props.width : "fit-content")};
  max-width: 100%;
  margin: 0.2rem;

  &:hover {
    box-shadow: 0 8px 16px 0 #404b69;
  }
`
export const UserNameAvater = styled("div")`
  border-radius: 50%;
  background-color: #fff;
  border: 2px solid #f38e27;
  height: 35px;
  width: 35px;
`
export const SearchContainer = styled("div")``

export const SearchInputBar = styled("input").attrs({ type: "text" })`
  border-radius: 15px;
  border: 0.5px solid ${props => props.theme.colors.brand.primary};
`
interface IKeyIndicator {
  bg_color: string
}
export const MapKeyIndicator = styled("button")<IKeyIndicator>`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border-width: 0px;
  justify-content: center;
  align-self: center;
  align-items: center;
  background-color: ${props => props.bg_color};
`
