import React from "react"
import { Provider } from "react-redux"
import { SnackbarProvider } from "notistack"

import store from "./shared/services/store"
import Theme from "./shared/infrastructure/theme"
import RouteWrapper from "./shared/infrastructure/routes"

function App() {
  return (
    <Provider store={store}>
      <SnackbarProvider maxSnack={1}>
        <Theme>
          <RouteWrapper />
        </Theme>
      </SnackbarProvider>
    </Provider>
  )
}

export default App
