import { createSlice, PayloadAction } from "@reduxjs/toolkit"

const slice = createSlice({
  name: "theme",
  initialState: {
    dark: false,
  } as {
    dark: boolean
  },
  reducers: {
    setTheme: (state, { payload: { theme } }: PayloadAction<{ theme: boolean }>) => {
      state.dark = theme
    },
  },
  extraReducers: builder => {},
})

export const { setTheme } = slice.actions

export default slice.reducer
